<style scoped>
.rank-tbl-mob {
  display: block;
}

.rank-tbl .table td,
.table th {
  border-top: 1px solid #dee2e6;
}

.rank-tbl .table tr:hover td,
.table tr:hover th {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.rank-tbl-mob table tbody tr td:first-child {
  background: #59636b57;
}

.rank-tbl-mob .table-outer {
  border: 0px solid #e1e1e1;
  padding: 0px;
  overflow-y: scroll !important;
}

.rank-tbl-mob table tbody tr:nth-child(1) td:first-child span,
.rank-tbl-mob table tbody tr:nth-child(2) td:first-child {
  background: #fcb913 !important;
}

.rank-tbl-mob table tbody tr:nth-child(3) td:first-child span,
.rank-tbl-mob table tbody tr:nth-child(4) td:first-child {
  background: #62c3ec !important;
}

.rank-tbl-mob table tbody tr:nth-child(5) td:first-child span,
.rank-tbl-mob table tbody tr:nth-child(6) td:first-child {
  background: #ea5f28 !important;
}



td.result-second-row {
  border-bottom: 1px solid #e1e1e1 !important;
}

.rank-tbl-mob table tr td span {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  width: 45px;
  height: 60px;
  display: inline-block;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rank-tbl-mob table tr.active td {
  font-weight: 700;
}

.rank-tbl-mob table tr td {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  align-items: center;
  color: #000000;
  background-color: transparent;
  border: none;
  vertical-align: middle;
}

.rank-tbl-mob .teamsText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 19px;
  color: #59636b;
  text-transform: uppercase;
}

.hide-row-mob {
  background: #ffffff00;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #000000;
}

div.rank-tbl-mob table tbody tr td>div {
  display: flex;
  align-items: center;
}

.hide-row-mob td p {
  margin: 1px 0;
}

.hide-row-mob td p.light-txt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;


  color: #59636b;
}

.hide-row-mob td {
  padding: 12px 21px;
}

.user-category {
  background: #000000;
  color: #ffffff;
  border-radius: 50%;
  margin-left: -18px;
  font-size: 12px;
  margin-bottom: -24px;
  height: 18px;
  width: 18px;
  text-align: center;
  vertical-align: middle;
}

.hide-row-mob td p strong {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 17px;

  color: #000000;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}



.rank-tbl table tr th {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  padding: 12px 12px;
  background: #000000;
}

.rank-tbl table tbody tr:hover {
  border: 1px solid #000000;
}


.rank-tbl table tr td span {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 19px;
  text-align: center;
  width: 69px;
  height: 69px;
  display: inline-block;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.teamsText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #59636b;
  margin: 0;
}

.rank-tbl table tbody tr {
  background: #fff;
  border: 1px solid #dee2e6;
}

.rank-tbl table tr.active td span {
  background: #91c951;
  color: #ffffff;
}


.rank-tbl table tr.active td {
  font-weight: 700;
  padding: 0px 20px;
}

.rank-tbl table tr td .jersey-img img {
  width: 100%;
  height: 100%;
}

.rank-tbl table tr td .jersey-img {
  width: 64px;
  height: 64px;
  text-align: center;

}

.rank-tbl table tr td .jersey-img img {
  width: 100%;
  height: 100%;
}


.rank-tbl table tr td {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  color: #000000;
  background-color: transparent;
  padding: 0px 20px;
  vertical-align: middle;
}

.rank-tbl .table-outer {
  border: 0px solid #e1e1e1;
  padding: 0px;
  overflow-y: scroll !important;
}

.rank-tbl .div-flex {
  display: flex;
  align-items: center;
  justify-content: inherit;
}

.vis-none {
  visibility: hidden;
}

.table.dataTable tr td.separate-list {
  border-left: 0px solid #fcb913;
  padding: 0;
  overflow-y: scroll !important;
}

.table.dataTable tr td.separate-list ul li {
  min-height: 40px;
  border-bottom: 0px solid #fcb913;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.txt-flex-s {
  justify-content: flex-start !important;
  padding-left: 12px;
}



.table.dataTable tr td.separate-list ul li div {
  display: inline-block;
}

.table.dataTable tr td.separate-list img {
  max-width: 20px !important;
  margin-right: 5px;
}



.rank-tbl table tbody tr:nth-child(2) td:first-child span {
  background: #fcb913;
}

.rank-tbl table tbody tr:nth-child(3) td:first-child span {
  background: #00b2e3;
}

.rank-tbl table tbody tr:nth-child(4) td:first-child span {
  background: #ea5f28;
}

.rank-tbl table tbody tr td:first-child span {
  background: #59636b57;
  width: 52px;
  height: 80px;
  margin-left: -20px;
}

.rank-row-string {
  background: #ff5f2ac2 !important;
}


@media (max-width: 575px) {
  .rank-tbl .container {
    padding: 0;
  }

  .rank-tbl-mob .container {
    padding: 0;
  }

  .rank-tbl-mob table tr td {
    font-size: 10px;
  }
}


table.table.overall-ind-table tr td {
  padding: 0;
}

.result-col {
  float: left;
  margin: 9px;
}

.playerNameTruncate {
  white-space: nowrap;
  width: 155px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.playerNameTruncateMbl {
  white-space: nowrap;
  width: 120px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
</style>

<template>
  <page-loader v-if="isOpenLoader || loader" style="margin-top: 50px" />

  <!--tbl start-->
  <div v-if="isMobileDevice" class="rank-tbl-mob">
    <div class="container-fluid p-0">
      <div class="table-outer">
        <table class="table overall-ind-table">
          <tbody>
            <template v-for="(row, index) in players" :key="index">
              <tr :class="valTypeCheck(row.rank)" scope="row">
                <td scope="col" width="20px">
                  <span :class="index == 0 ? 'active' : ''">{{ row.rank }}</span>
                </td>
                <td scope="col" width="60px">
                  <div class="jersey-img div-flex">
                    <div v-html="row.jerseyImage"></div>
                    <div class="user-category" v-if="row.categoryId != 0">
                      {{ row.categoryId }}
                    </div>
                  </div>
                </td>
                <td scope="col" style="width: 125px !important">
                  <p
                    class="mb-0 playerNameTruncateMbl"
                    v-html="row.userFullName"
                  ></p>
                  <p class="teamsText">
                    <strong
                      v-html="mapCountryFlag(row.userCountryFlag, '20px', '20px')
                        "
                    ></strong>
                    {{ row.teamName }}
                  </p>
                </td>
                <td
                  scope="col"
                  class="text-right pr-2"
                  v-if="hidePoints"
                  v-html="formatTime(row.finishedTime)"
                ></td>
                <td
                  scope="col"
                  class="text-right pr-2"
                  v-if="!hidePoints"
                  v-html="row.points"
                ></td>
              </tr>
              <tr scope="row" :class="valTypeCheck(row.rank) + ' ' + 'hide-row-mob'" >
                <td scope="col"></td>
                <td class="result-second-row" scope="col" colspan="4">
                  <div class="result-col">
                    <p><strong v-html="row.avgPower"></strong></p>
                    &nbsp;
                    <p class="light-txt">AVG (W)</p>
                  </div>
                  <div class="result-col">
                    <p><strong v-html="row.wattPerKG"></strong></p>
                    &nbsp;
                    <p class="light-txt">AVG (W/KG)</p>
                  </div>
                  <div class="result-col">
                    <p><strong v-html="row.avgHeartRate"></strong></p>
                    &nbsp;
                    <p class="light-txt">AVG (HR)</p>
                  </div>
                </td>
              </tr>

    
            </template>
           
            <template v-for="(row, index) in eventRemainingPlayers" :key="index">
              <tr  :class="valTypeCheck(row.rank)" scope="row">
                <td scope="col" width="20px">
                  <span :class="index == 0 ? 'active' : ''">{{ (players.length + index) + 1 }}</span>
                </td>
                <td scope="col" width="60px">
                  <div class="jersey-img div-flex">
                    <div v-html="mapJersey(row.JerseyId)"></div>
                    <div class="user-category" v-if="row.CategoryId != 0">
                      {{ row.CategoryId }}
                    </div>
                  </div>
                </td>
                <td scope="col" style="width: 125px !important">
                  <p
                    class="mb-0 playerNameTruncateMbl"
                    v-html="row.PlayerName"
                  ></p>
                  <p class="teamsText">
                    <strong
                      v-html="mapCountryFlag(row.CountryId, '20px', '20px')
                        "
                    ></strong>
                    {{ row.teamName || 'Individual' }}
                  </p>
                </td>
                <td
                  scope="col"
                  class="text-right pr-2"
                > -
              </td>
              </tr>
              <tr scope="row" :class="valTypeCheck(row.rank) + ' ' + 'hide-row-mob'" >
                <td scope="col"></td>
                <td class="result-second-row" scope="col" colspan="4">
                  <div class="result-col">
                    <p> -</p>
                    &nbsp;
                    <p class="light-txt">AVG (W)</p>
                  </div>
                  <div class="result-col">
                    <p>-</p>
                    &nbsp;
                    <p class="light-txt">AVG (W/KG)</p>
                  </div>
                  <div class="result-col">
                    <p>-</p>
                    &nbsp;
                    <p class="light-txt">AVG (HR)</p>
                  </div>
                </td>
              </tr>

    
            </template>
       
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!--tbl end-->

  <div v-else class="rank-tbl">
    <div class="container">
      
      <table class="table" style="border: 1px solid #f2f2f2">
        <tbody>
          <tr scope="row">
            <th scope="col">RANK</th>
            <th scope="col"></th>
            <th scope="col">RIDER</th>
            <th scope="col" >{{ getAvgText('W') }}</th>
            <th scope="col" v-if="!hideAvgVal">AVG (W/KG)</th>
            <th scope="col" >{{ getAvgText('HR') }}</th>
            <th scope="col" v-if="!hidePoints">POINTS</th>
            <th scope="col" v-if="hidePoints">TIME</th>
            <th scope="col" v-if="hidePoints && !isHandiCapEvent">GAP</th>
          </tr>

          <tr scope="row" v-if="!players.length" class="text-center">
            <td scope="col" colspan="8" style="line-height: 57px">
              {{ tableMessage }}
            </td>
          </tr>

          <tr
            scope="row"
            :class="valTypeCheck(row.rank)"
            v-for="(row, index) in players"
            :key="row.rank"
            @click="isLive ? $emit('spectatorMode', row) : openCompareModal(row)"
          >
            <td scope="col">
              <span :class="index === 0 && 'active'">{{ row.rank }}</span>
            </td>
            <td scope="col">
              <p
                v-html="mapCountryFlag(row.userCountryFlag, '40px', '40px')"
              ></p>
            </td>
            <td scope="col">
              <div class="div-flex">
                <div class="jersey-img div-flex">
                  <div v-html="row.jerseyImage"></div>
                  <div class="user-category" v-if="row.categoryId != 0">
                    {{ row.categoryId }}
                  </div>
                </div>
                <div class="">
                  <p
                    class="mb-0 playerNameTruncate"
                    v-html="row.userFullName"
                  ></p>
                  <p class="teamsText">{{ row.teamName }}</p>
                </div>
              </div>
            </td>
            <td scope="col">{{ row.avgPower }}</td>
            <td scope="col"  v-if="!hideAvgVal">{{ row.wattPerKG }}</td>
            <td scope="col">{{ row.avgHeartRate }}</td>

            <td scope="col" v-if="!hidePoints">
              {{ applyDash(row.selectionStatus) ? "-" : row.points }}
            </td>
            <td scope="col" v-if="hidePoints">
              {{
                applyDash(row.selectionStatus)
                ? "-"
                : formatTime(row.finishedTime)
              }}
            </td>
            <td scope="col" v-if="hidePoints && !isHandiCapEvent">
              {{
                applyDash(row.selectionStatus)
                ? "-"
                : "+" + formatTime(row.finishedTime - players[0].finishedTime)
              }}
            </td>
          </tr>

        
          
          <tr scope="row" :class="valTypeCheck(row.rank)"  v-for="(row, index) in eventRemainingPlayers" :key="index">
            <td scope="col">
              <span :class="index === 0 && 'active'">{{ 'DSQ' }}</span>
            </td>
            <td scope="col">
              <p
                v-html="mapCountryFlag(row.CountryId, '40px', '40px')"
              ></p>
            </td>
            <td scope="col">
              <div class="div-flex">
                <div class="jersey-img div-flex">
                  <div v-html="mapJersey(row.JerseyId)">
                    
                  </div>
                  <div class="user-category" v-if="row.categoryId != 0">
                    {{ row.CategoryId }}
                  </div>
                </div>
                <div class="">
                  <p
                    class="mb-0 playerNameTruncate"
                    v-html="row.PlayerName.toUpperCase()"
                  ></p>
                  <p class="teamsText"> {{ row.teamName || 'Individual' }}</p>
                </div>
              </div>
            </td>
            <td scope="col">
              <p >-</p>
            </td>
            <td scope="col">
              <p >-</p>
            </td>
            <td scope="col">
              <p >-</p>
            </td>
            <td scope="col">
              <p >-</p>
            </td>
            <td scope="col"  >
              {{ 'Not Crossed' }}
            </td>
          </tr>
        
        

        </tbody>
      </table>
    </div>
  
  </div>

  <PlayerProfileModal
    v-if="isShowPlayerProfileModal"
    :isShowPlayerProfileModal="isShowPlayerProfileModal"
    :activeItem="activeItem"
    @listenerChild="listenerChild"
    :event="event"
    :comparisonPlayerNames="comparisonPlayerNames"
    :segData="segData"
    :isCompareOpen="isCompareOpen"
    :comparePlayerId="comparePlayerId"
    :overAllData="overAllData"
  />
</template>

<script>
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-colreorder-bs4/js/colReorder.bootstrap4.min.js";
import "datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css";
import "datatables.net-fixedcolumns-bs4/js/fixedColumns.bootstrap4.min.js";
import $ from "jquery";

//import config from '@/config/app.config.ts';
import {
  formatTime,
  IsSeriesPointAndTimeBasedEvents,
  IsSinglePointAndTimeBasedEvents,
  IsSinglePointBasedEvents, isUCIEvent, mapCountryFlag,
  isRunningEvent,
  MinuesToDaysHoursMinsSeconds,
  mapJersey
} from "@/shared/events/event.helpers.ts";
import { columnsV2, wattPerKg, wattsData } from "@/views/events/result.dto.ts";

import { mapActions, mapGetters } from "vuex";
import PlayerProfileModal from "../../shared/components/PlayerProfileModal.vue";

export default {
  name: "DataTable-Vue",
  emits: ["spectatorMode"],
  props: [
    "isWatt",
    "result",
    "event",
    "activeItem",
    "isLive",
    "loader",
    "selectedColumns",
    "isSeriesEvent",
    "noResultsDataMessage",
    "isMobileDevice",
    "isHandiCapEvent",
    "isEleminationRace",
    "totalEventPlayers",
    "eliEventPlayersInfo"

  ],
  components: {
    PlayerProfileModal,
  },
  data() {
    return {
      columns: columnsV2,
      wattPerKg: wattPerKg,
      wattsData: wattsData,
      isEleminationRaceProp: this.isEleminationRace,
      totalEventPlayersProp: this.totalEventPlayers,
      isShowPlayerProfileModal: false,
      formatTime: formatTime,
      MinuesToDaysHoursMinsSeconds: MinuesToDaysHoursMinsSeconds,
      mapCountryFlag: mapCountryFlag,
      mapJersey: mapJersey,
      data: this.result,
      isOpenLoader: false,
      tableHeight: this.result.length * 43,
      tableSort: {},
      tableMessage: this.noResultsDataMessage,
      players: [],
      hidePoints: true,
      hideAvgVal: false,
      comparePlayerId: "",

      comparisonPlayerNames: [],
      isCompareOpen: true,
      compareCols: [
        {
          name: "AVG (POWER)",
          key: "avgPower",
          unit: "W",
        },
        {
          name: "AVG (W/KG)",
          key: "wattPerKG",
          unit: "W/Kg",
        },
        {
          name: "AVG (HR)",
          key: "avgHeartRate",
          unit: "bpm",
        },
        {
          name: "15 SEC POWER",
          key: "wattsPerKG5sec",
          unit: "W/kg",
        },
        {
          name: "30 SEC POWER",
          key: "wattsPerKG20sec",
          unit: "W/kg",
        },
        {
          name: "1 MIN POWER",
          key: "wattsPerKG1min",
          unit: "W/kg",
        },
        {
          name: "5 MIN POWER",
          key: "wattsPerKG5min",
          unit: "W/kg",
        },
        {
          name: "20 MIN POWER",
          key: "wattsPerKG20min",
          unit: "W/kg",
        },
      ],

      addtionalCols: [
        {
          key: "wattsPerKG5sec",
          addedKey: "watts5sec",
          unit: "W",
        },
        {
          key: "wattsPerKG20sec",
          addedKey: "watts20sec",
          unit: "W",
        },
        {
          key: "wattsPerKG1min",
          addedKey: "watts1min",
          unit: "W",
        },
        {
          key: "wattsPerKG5min",
          addedKey: "watts5min",
          unit: "W",
        },
        {
          key: "wattsPerKG20min",
          addedKey: "watts20min",
          unit: "W",
        },
      ],

      segData: [],
      overAllData: [],
      overCompareCols: [
        {
          name: "Distance",
          key: "distance_total",
          unit: "km",
        },
        {
          name: "Climbed",
          key: "elevation_total",
          unit: "m",
        },
        {
          name: "Activities",
          key: "total_activities",
          unit: "",
        },
        {
          name: "Time Spent",
          key: "time_spent",
          unit: "",
        },
        {
          name: "Longest Ride",
          key: "longest_ride",
          unit: "km",
        },
        {
          name: "Total Races",
          key: "total_races",
          unit: "",
        },
      ],

      eventRemainingPlayers: []


    };
  },
  computed: {
    ...mapGetters("player", ["comparePlayerData", "loggedInPlayerData"]),
  },
  watch: {
    noResultsDataMessage: {
      handler: function (values) {
        this.tableMessage = values;
      },
      deep: true,
      immediate: true,
    },
  },

  async mounted() {
    await this.showHidePoints();
    await this.showHideAvgPwoer();
    this.players = await this.getRows();


  },

  methods: {
    ...mapActions("player", [
      "getComparePlayerDetails",
      "getOverallPlayersData",
    ]),

    valTypeCheck(val) {
      if (this.isEleminationRaceProp) {
        const aAsNumber = Number(val);
        return isNaN(aAsNumber) ? 'rank-row-string' : 'rank-row-number'
      }
      return ''
    },



    applyDash(status) {
      return (!this.isLive && (this.event.result_publish_status == "official" || this.isSeriesEvent) && !this.isEleminationRaceProp && ["DNF", "DSQ", "DNS", "ANL", "TIE", "SFT"].includes(status));
    },

    getRemainingPlayersArray(resultData, totalPlayersData) {
      const playerIds = new Set(resultData.map((item) => item.userId));
      this.eventRemainingPlayers = totalPlayersData.filter((item) => !playerIds.has(item.PlayerId));

    },

    async showHideAvgPwoer() {
      if (isRunningEvent(this.event)) {
        this.hideAvgVal = true;
      }
    },
    getAvgText(txtType) {
      if (txtType == 'W') {
        if (this.hideAvgVal) {
          return 'AVG PACE';
        } else {
          return 'AVG (W)'
        }
      } else if (txtType == 'HR') {
        if (this.hideAvgVal) {
          return 'HR BPM';
        } else {
          return 'AVG (HR)'
        }
      }
    },
    async showHidePoints() {
      if (
        IsSeriesPointAndTimeBasedEvents(this.event) &&
        this.isSeriesEvent &&
        !["individual", "master", "young"].includes(this.activeItem.type)
      ) {
        this.hidePoints = false;
      } else if (
        IsSinglePointAndTimeBasedEvents(this.event) &&
        !this.isSeriesEvent &&
        !["individual"].includes(this.activeItem.type)
      ) {
        this.hidePoints = false;
      } else if (IsSinglePointBasedEvents(this.event) && !this.isSeriesEvent) {
        this.hidePoints = false;
      } else if (isUCIEvent(this.event)) {
        this.hidePoints = false;
      } else {
        this.hidePoints = true;
      }

    },

    handleScroll() {
      const scrollDiv = document.getElementById("individual-result-body");

      if (window.scrollY && scrollDiv.scrollTop > 0) {
        $(".dataTables_scrollHead, .DTFC_LeftHeadWrapper").css({
          // scroll to that element or below it
          position: "static",
        });

        scrollDiv.scrollTop = 0;
      }
    },

    tblBodyScroll() {
      const scrollDiv = document.getElementById("individual-result-body");
      const divTop = document
        .getElementById("individual-result")
        .getBoundingClientRect().top;
      if (scrollDiv.scrollTop > 0) {
        $(".dataTables_scrollHead").css({
          // scroll to that element or below it
          position: "fixed",
          top: parseInt(divTop, "0") + "px",
          "z-index": 99999,
          width: scrollDiv.offsetWidth,
        });

        $(".DTFC_LeftHeadWrapper").css({
          // scroll to that element or below it
          position: "fixed",
          top: parseInt(divTop, "0") + "px",
          left: 15,
          "z-index": 99999999,
          width: 150,
        });
      } else {
        $(".dataTables_scrollHead, .DTFC_LeftHeadWrapper").css({
          // scroll to that element or below it
          position: "static",
        });
      }
    },

    hideColumn() {
      this.tableSort.columns().visible(false);

      this.selectedColumns.map((col) => {
        this.tableSort.column(`${col}:name`).visible(true);
      });

      this.tableSort.columns.adjust().draw();
    },

    getColumns(withData = true) {
      const cols = [
        ...this.columns,
        ...this.wattsData,
        ...this.wattPerKg,
      ].filter((e) => e);
      if (withData) return cols;
      return cols.map((c) => ({ data: c.id }));
    },

    getColumnsDefs() {
      const columns = [
        { orderable: false, targets: "no-sort" },
        { orderable: false, targets: -1, visible: false },
        // {className: 'tx-l', 'targets': [4]},
      ];
      this.getColumns().map((col, i) => {
        columns.push({
          width: col.width || 100,
          className: col.className || "text-center",
          targets: i,
          name: col.id,
          orderable: col.orderable || false,
        });
      });
      return columns;
    },

    applyDataMapping(data) {

      let rankedData = [];
      if (
        (this.isSeriesEvent && IsSeriesPointAndTimeBasedEvents(this.event) && ["overallgate", "master", "youth"].includes(this.activeItem.type)) ||
        (this.isSeriesEvent && isUCIEvent(this.event) && this.activeItem.type === "individual") ||
        (!this.isSeriesEvent && IsSinglePointAndTimeBasedEvents(this.event) && this.activeItem.type === "overallgate") ||
        (!this.isSeriesEvent && IsSinglePointBasedEvents(this.event) && this.activeItem.type === "overallgate")
      ) {
        rankedData = data.sort((a, b) => {
          if (a.points > b.points) {
            return -1;
          } else if (a.points < b.points) {
            return 1;
          }

          if (a.finishedTimeOverall < b.finishedTimeOverall) {
            return -1;
          } else if (a.finishedTimeOverall > b.finishedTimeOverall) {

            return 1;
          }
          return 0;
        });
      } else {

        if (this.isHandiCapEvent) { //sort based on end time in handicap events
          rankedData = data.sort((a, b) => a.endAt - b.endAt)
        }
        else {
          rankedData = data.sort((a, b) => a.finishedTime - b.finishedTime);
        }
      }



      return rankedData.map((r, index) => {

        const row = {};
        //const rank = r['rank'];
        let rank = 0
        if (this.event.result_status !== 'official' && this.event.registration_required && !this.isSeriesEvent && !this.isEleminationRaceProp) {
          rank = index + 1
        } else {
          rank = r.selectionStatus ? r.selectionStatus : index + 1
        }
        const firstFinishedTime = rankedData[0].finishedTime;
        const rowData = Object.assign(
          { gapTimeNew: r.finishedTime - firstFinishedTime },
          r
        );
        this.getColumns().forEach((c) => {
          row[c.id] = this.colFormat(rank, index, rowData, c);
        });
        return row;
      });
    },

    getRows() {
      let results = [];


      if (this.isSeriesEvent || IsSinglePointBasedEvents(this.event) || this.isEleminationRaceProp || !this.isLive && this.event.registration_required && this.event.result_status === 'official') {
        // remove deleted players
        this.data = this.data.filter((x) => !x.deleted_at);
        // filter dq/nonDq players
        const status = ["DNF", "DSQ", "DNS", "ANL", "TIE", "SFT"];
        const qItems = this.applyDataMapping(
          this.data.filter((x) => !status.includes(x.selectionStatus))
        );
        const dqItems = this.applyDataMapping(
          this.data.filter((x) => status.includes(x.selectionStatus)),
          false
        );
        results = [...qItems, ...dqItems];
      } else {
        results = this.applyDataMapping(this.data);
      }

      if (results?.length && this.isEleminationRaceProp) {
        this.getRemainingPlayersArray(results, this.eliEventPlayersInfo)
      }
      return results;
    },

    colFormat(rank, index, row, col) {
      let colData = "";
      if (col.format && typeof col.format === "function") {
        colData += col.format(row[col.id]);
      } else if (col.format) {
        colData += `${row[col.id]} ${" "} ${col.format}`;
      } else if (col.id === "rank") {
        colData += rank;
      } else {
        colData += row[col.id] ??= col.defaultValue;
      }
      return colData;
    },

    listenerChild(modalStatus) {
      this.isShowPlayerProfileModal = modalStatus;
    },

    async openCompareModal(rowData) {
      this.isOpenLoader = true;

      this.comparePlayerId = rowData?.userId || null;

      if (this.activeItem.type == "overallgate") {
        this.isOpenLoader = false;
        return;
      }

      //check if overall Data exists to get
      if (this.activeItem.type == "individual") {
        // const res = await this.getOverallPlayersData({ loggedPlayer: "1d170444-9e1e-11ee-bd0e-066d4aa16333", comparePlayer: "1d170444-9e1e-11ee-bd0e-011d4aa16334" })
        const res = await this.getOverallPlayersData({
          loggedPlayer: this.loggedInPlayerData?.userId,
          comparePlayer: this.comparePlayerId,
        });
        if (!res?.data) {
          this.isShowPlayerProfileModal = false;
          this.isOpenLoader = false;
          return;
        } else {
          const overResData = res?.data.filter(
            (item) => "comparePlayer" in item
          );
          if (!overResData.length) {
            this.isShowPlayerProfileModal = false;
            this.isOpenLoader = false;
            return;
          }
        }

        // const overAllDataCopy = res?.data
        this.overAllData = res?.data;
        // this.overAllData = !this.loggedInPlayerData ? this.overAllData.map((item) => { return { name: item.name, comparePlayer: item.comparePlayer } }) : overAllDataCopy

        //apply units to data
        this.overAllData = this.overAllData.map((item) => {
          const col = this.overCompareCols.find((i) => i.key == item.key);
          let isValueConvertingCol = false;
          if (col) {
            if (["time_spent"].includes(col.key)) {
              isValueConvertingCol = true;
            }

            if (item.comparePlayer) {
              item.comparePlayer = isValueConvertingCol
                ? MinuesToDaysHoursMinsSeconds(item.comparePlayer)
                : col.unit
                  ? parseFloat(item.comparePlayer).toFixed(2) + " " + col.unit
                  : parseFloat(item.comparePlayer).toFixed(2);
            }
            if (item?.loggedPlayer) {
              item.loggedPlayer = isValueConvertingCol
                ? MinuesToDaysHoursMinsSeconds(item.loggedPlayer)
                : col.unit
                  ? parseFloat(item.loggedPlayer).toFixed(2) + " " + col.unit
                  : parseFloat(item.loggedPlayer).toFixed(2);
            }

            delete item.key;
          }
          return item;
        });
      }

      //get player details
      await this.getComparePlayerDetails({
        comparePlayerId: this.comparePlayerId,
        loggedPlayerId: this.loggedInPlayerData?.userId,
        eventId: this.event.serverEventId,
      });

      if (
        !this.comparePlayerData?.LoggedPlayerParticipated ||
        this.comparePlayerId === this.loggedInPlayerData?.userId
      ) {
        this.isCompareOpen = false;
      } else {
        this.isCompareOpen = true;
      }

      let currentPlayerJerseyImage = "";
      this.isShowPlayerProfileModal = true;
      const dummyData = [];
      this.players.map((item) => {
        if (
          [this.comparePlayerId, this.loggedInPlayerData?.userId].includes(
            item.userId
          )
        ) {
          for (const [key, value] of Object.entries(item)) {
            let obj = {};
            const col = this.compareCols.find((i) => i.key == key);
            const extraUnit = this.addtionalCols.find((i) => i.key == key);
            if (col) {
              obj = { ...obj, key: col.key, name: col.name };
              if (item.userId == this.loggedInPlayerData?.userId) {
                currentPlayerJerseyImage = item.jerseyImage;
                obj.loggedPlayer = `${parseFloat(value, 2)} ${col.unit} ${extraUnit
                  ? "(" +
                  item[extraUnit.addedKey] +
                  " " +
                  extraUnit.unit +
                  ")"
                  : ""
                  }`;

                // parseFloat(value, 2) + " " + col.unit + " " + (extraUnit ? item[extraUnit.addedKey] + " " + extraUnit.unit : "")
              } else {
                obj.comparePlayer = `${parseFloat(value, 2)} ${col.unit} ${extraUnit
                  ? "(" +
                  item[extraUnit.addedKey] +
                  " " +
                  extraUnit.unit +
                  ")"
                  : ""
                  }`;
                // parseFloat(value, 2) + " " + col.unit + " " + (extraUnit ? item[extraUnit.addedKey] + " " + extraUnit.unit : "")
              }
              dummyData.push(obj);
            }
          }
        }
      });

      this.segData = Object.values(
        dummyData.reduce((r, o) => {
          Object.assign((r[o.key] ??= {}), o);
          return r;
        }, {})
      );

      this.segData = this.segData.filter((item) => delete item.key);
      this.comparisonPlayerNames = [];
      if (
        this.loggedInPlayerData &&
        this.comparePlayerData?.LoggedPlayerParticipated
      ) {
        const loggedPlayerName =
          this.loggedInPlayerData?.firstName +
          " " +
          this.loggedInPlayerData?.lastName || "";
        this.comparisonPlayerNames.push({
          name: loggedPlayerName,
          order: 3,
          userInfo: {
            gender: this.loggedInPlayerData.gender,
            jerseyImage: currentPlayerJerseyImage,
          },
        });
      }
      this.comparisonPlayerNames.push({
        name: rowData?.userFullName,
        order: 4,
        userInfo: {
          gender: this.comparePlayerData?.gender,
          jerseyImage: rowData.jerseyImage,
        },
      });
      this.isOpenLoader = false;
    },
  },
};
</script>
